<template lang="pug">
div(ref='observerEl')
  component(
    v-if='videoPlayerIsVisible'
    :is='AsyncVideoYouTubePlayer'
    v-bind='props'
    v-on="listeners"
  )
    template(v-for='(_, slotName) in $slots'  v-slot:[slotName]='slotProps')
      slot(:name='slotName' v-bind='slotProps')
  EcreativAtomsSpinner(v-else)
</template>

<script setup lang="ts">
import { defineAsyncComponent, ref } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';
import EcreativAtomsSpinner from './atoms/Spinner.vue';

interface VideoPlayer {
  videoId?: string;
  title?: string;
  subTitle?: string;
  time?: string;
  loading?: boolean;
  error?: string;
  isRounded?: boolean;
  featured?: boolean;
}

const props = withDefaults(defineProps<VideoPlayer>(), {
  videoId: 'Z4FeEcnitvI',
  title: '',
  subTitle: '',
});

const emit = defineEmits<{
  (e: 'update-last-player', videoRef: any): void;
  (e: 'init-player', videoRef: any): void;
}>();

// Forward events explicitly
const listeners = {
  'update-last-player': (videoRef: any) => emit('update-last-player', videoRef),
  'init-player': (videoRef: any) => emit('init-player', videoRef),
};

// Visibility state
const videoPlayerIsVisible = ref(false);

// Reference to the placeholder element
const observerEl = ref<HTMLElement | null>(null);

// Define async component with loading/error handling
const AsyncVideoYouTubePlayer = defineAsyncComponent({
  loader: () => import('./atoms/VideoYouTubePlayer.vue'),
  loadingComponent: EcreativAtomsSpinner,
  delay: 200,
  timeout: 3000,
});

// Initialize intersection observer
const { stop } = useIntersectionObserver(
  observerEl,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      videoPlayerIsVisible.value = true;
      stop(); // Stop observing after first intersection
    }
  },
  {
    rootMargin: '100px 0px',
    threshold: 0,
  },
);
</script>
